.img {
  display: block;
  max-width: 100%;

  &--full {
    width: 100%;
  }

  &--dt {
    display: none;

    @include minW(992) {
      display: block;
    }
  }

  &--mb {
    display: block;

    @include minW(992) {
      display: none;
    }
  }
}
