.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 99;

  @include minW(992) {
    top: 7.9rem;
    height: calc(100% - 7.9rem);
  }

  &.is-show {
    display: flex;
    align-items: flex-end;

    @include minW(992) {
      align-items: flex-start;
    }
  }

  &__dialog {
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 5rem;
    border: 1.6rem 1.6rem 0 0;

    @include minW(992) {
      padding-top: 0;
      padding-bottom: 1.5rem;
      border: 0;
    }
  }

  &__body {
    margin: 0 -1.5rem;

    @include minW(992) {
      position: relative;
      margin: 0;
      display: flex;
      justify-content: flex-end;
    }

    .form {
      border-radius: 1.6rem 1.6rem 0 0;
      width: 100%;

      @include minW(992) {
        width: 36rem;
        border-radius: 0;
        box-shadow: 0 0 5px $gray50-color;
      }

      @include minW(1600) {
        width: 48rem;
      }

      &__foot {
        text-align: center;

        @include minW(992) {
          display: none;
        }

        .btn {
          background-color: transparent;
          font-size: 1.4rem;
          line-height: 1.6rem;
          font-weight: 400;
          color: $gray50-color;
        }
      }
    }
  }
}

// .popup {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   width: 100%;
//   height: 100%;
//   display: none;
//   overflow-y: auto;
//   padding-top: 5rem;
//   z-index: 99;

//   @include minW(992) {
//     top: 7.9rem;
//     height: calc(100% - 7.9rem);
//     padding-top: 0;
//   }

//   &.is-show {
//     display: flex;
//     align-items: flex-end;
//   }

//   &__dialog {
//     width: 100%;
//     margin: 0 auto;

//     @include minW(992) {
//       max-width: 127.8rem;
//       padding: 0 1.5rem;
//     }

//     @include minW(1600) {
//       max-width: 169.4rem;
//     }
//   }

//   &__body {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     align-items: flex-end;

//     @include minW(992) {
//       padding-bottom: 1.5rem;
//       align-items: flex-start;
//       justify-content: flex-end;
//     }

//     .form {
//       width: 100%;
//       border-radius: 1.6rem 1.6rem 0 0;

//       @include minW(992) {
//         border-radius: 0;
//         width: 36rem;
//         box-shadow: 0 0 5px $gray50-color;
//       }


//       @include minW(1600) {
//         width: 48rem;
//       }
//     }
//   }
// }
