.banner-about {
  position: relative;
  margin-bottom: 6rem;

  @include minW(992) {
    margin-bottom: 8rem;
    min-height: 48rem;
  }

  &--contact {
    .banner {
      &__title {
        @include minW(992) {
          padding-top: 20.4rem;
        }
      }
    }
  }

  &__thumb {
    position: absolute;
    left: 0;
    top: 0;
    background-color: $red-color;
    width: 100%;
    height: 23rem;
    overflow: hidden;

    @include minW(992) {
      height: 36rem;
    }

    @include minW(1600) {
      height: 48rem;
    }

    .img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }

  &__body {
    position: relative;
    padding-top: 13.7rem;
    z-index: 2;

    @include minW(992) {
      padding-top: 11.4rem;
    }

    @include minW(1600) {
      padding-top: 15.2rem;
    }
  }

  &__title {
    font-size: 3.2rem;
    line-height: 3.2rem;
    font-weight: 700;
    color: $white-color;

    @include minW(992) {
      font-size: 4.8rem;
      line-height: 5.4rem;
      padding-top: 9.9rem;
      margin-bottom: 0;
    }

    @include minW(1600) {
      font-size: 6.4rem;
      line-height: 7.2rem;
      padding-top: 13.2rem;
      margin-bottom: 0;
    }
  }

  &__content {
    background-color: $red-color;
    padding: 1.6rem;
    color: $white-color;
    font-size: 1.6rem;
    line-height: 2rem;

    @include minW(992) {
      font-size: 1.5rem;
      line-height: 2.1rem;
      padding: 3rem;
    }

    @include minW(1600) {
      font-size: 2rem;
      line-height: 2.8rem;
      padding: 4rem;
    }
  }
}

.about-us {
  position: relative;
  margin-bottom: 10rem;

  @include minW(992) {
    margin-bottom: 14rem;
  }

  &__dt {
    display: none;

    @include minW(992) {
      display: block;
    }
  }

  &__mb {
    display: block;

    @include minW(992) {
      display: none;
    }
  }

  &__list {
    position: relative;
    margin-bottom: 0;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray90-color;
    margin-bottom: 8px;
    padding: 2rem 1.6rem 2rem 2.4rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $black-color;
    border-radius: 4px;

    .icon {
      font-size: 2.4rem;
    }
  }
}

.slider {
  margin: 0 -2.4rem 1.5rem;

  @include minW(992) {
    display: flex;
    margin: 0 0 1.5rem;
  }

  &__thumb {
    margin: 1.5rem 0 3rem;
    overflow: hidden;

    @include minW(992) {
      flex: 0 0 auto;
      width: 16.633%;
      margin: 0;
    }
  }

  &__show {
    @include minW(992) {
      flex: 0 0 auto;
      width: 83.367%;
      padding-right: 8px;
    }
  }

  .slick {
    &--nav {
      .slick {
        &-list {
          padding: 0 2.4rem;

          @include minW(992) {
            padding: 0;
          }
        }

        &-slide {
          cursor: pointer;
          padding: 0 0.6rem;
          filter: grayscale(100%);

          @include minW(992) {
            padding: 0 0 0.6rem;
          }

          &.slick-current {
            opacity: 1;
            filter: grayscale(0%);
          }
        }
      }
    }

    &-list {
      margin: 0;
    }

    &-slide {
      margin: 0;

      .img {
        margin-bottom: 0;
      }
    }
  }
}

.scrollbar-outer {
  max-height: 64rem;
}

.contact-list {
  padding-right: 9.6rem;
}

.about-detail {
  position: relative;
  overflow: hidden;
  padding-top: 8rem;
  padding-bottom: 3rem;

  @include minW(992) {
    padding-top: 12rem;
    padding-bottom: 9rem;
    background-color: $gray80-color;
  }

  @include minW(1600) {
    padding-top: 16rem;
    padding-bottom: 12rem;
  }

  &__head {
    margin-bottom: 4rem;

    .back {
      position: relative;
      color: $black-color;
      font-family: $second-font;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 700;
      text-align: center;
      padding-left: 6.6rem;

      .icon {
        font-size: 3.2rem;
        color: $gray50-color;
        position: absolute;
        left: 0;
        top: -4px;
      }
    }
  }

  .img {
    margin-bottom: 1.5rem;
  }

  .slider {
    margin: 0 0 1.5rem;

    .slick {
      &--nav {
        .slick-list {
          padding: 0;
        }
      }
    }
  }
}

.card-contact {
  position: relative;
  margin-bottom: 4.8rem;

  &__title {
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 700;
    color: $gray25-color;
  }

  &__map {
    position: relative;
    width: 100%;
    height: 24rem;
    margin-bottom: 1.5rem;
  }
}
