.account {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url('../img/bg-sign.jpg') no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;

  &__main {
    max-width: 147rem;
    padding: 0 1.5rem;
    margin: 0 auto;
  }

  &__logo {
    display: inline-block;
    margin-bottom: 6.8rem;
  }

  &__title {
    font-family: $second-font;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.2rem;
    margin-bottom: 5px;

    @include minW(992) {
      font-size: 4.8rem;
      line-height: 5.4rem;
      margin-bottom: 0;
    }

    @include minW(1600) {
      font-size: 6.4rem;
      line-height: 7.2rem;
    }
  }

  &__subtitle {
    font-family: $second-font;
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 400;
    margin-bottom: 2.4rem;

    @include minW(992) {
      font-size: 2.4rem;
      line-height: 2.7rem;
    }

    @include minW(1600) {
      font-size: 3.2rem;
      line-height: 3.6rem;
    }
  }

  &__content {
    max-width: 68.6rem;
    color: $white-color;
    margin-bottom: 6rem;
  }

  &__desc {
    font-size: 1.4rem;
    line-height: 1.6rem;
    max-width: 60.8rem;
    margin-bottom: 0;

    @include minW(992) {
      font-size: 1.5rem;
      line-height: 2.1rem;
    }

    @include minW(1600) {
      font-size: 2rem;
      line-height: 2.8rem;
    }
  }

  .row {
    align-items: center;
  }
}

.form {
  &--account {
    background-color: $white-color;
    padding: 2.4rem 1.6rem;

    @include minW(992) {
      padding: 2.4rem 3.6rem;
    }

    @include minW(1600) {
      padding: 3.2rem 4.8rem;
    }
  }

  &__group {
    margin-bottom: 1.5rem;
  }

  &__label {
    position: relative;
    font-family: $second-font;
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 500;
    margin-bottom: 4px;

    @include minW(992) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    @include minW(1600) {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }

  &__forgot {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 400;
    color: $gray50-color;

    @include minW(992) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    @include minW(1600) {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  &__control {
    outline: 0;
    border: 0;
    border-bottom: 1px solid $gray80-color;
    display: block;
    width: 100%;
    height: 4rem;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $gray25-color;
    background-color: $white-color;

    @include minW(992) {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }

    @include minW(1600) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  &__title {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 8px;

    @include minW(992) {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }

    @include minW(1600) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  &__sign {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 3.8rem;

    @include minW(992) {
      font-size: 1.1rem;
      line-height: 1.3rem;
    }

    @include minW(1600) {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }

  &__note {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $gray25-color;

    @include minW(992) {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  &__error {
    color: $red-color;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .g-recaptcha {
    margin-top: 5rem;
    transform: scale(0.77);
    transform-origin: 0 0;

    @include minW(1600) {
      transform: scale(1);
    }
  }

  .btn {
    margin-top: 3rem;
  }
}
