.select2-container {
  display: block;
  margin: 0;
  position: relative;
  outline: 0;
  z-index: 9;

  &--disabled {
    .select2-selection {
      border-color: $gray50-color !important;

      &__rendered {
        color: $gray25-color !important;
      }
    }
  }

  &--focus {
    .select2-selection {
      border-color: $black-color;
    }
  }

  &--open {
    z-index: 100;

    .select2-selection {
      &__arrow {
        top: 1.4rem;

        &::before {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
  outline: 0;

  &:focus {
    outline: 0;
  }
}

.select2-selection {
  position: relative;
  display: block;
  border: 1px solid #C8C8C8;
  border-radius: 0;
  outline: 0;

  &__rendered {
    display: block;
    height: 4.8rem;
    user-select: none;
    outline: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 1.4rem 3.4rem 1.4rem 1.8rem;

    &--value {
      font-size: 1.6rem;
      line-height: 2rem;
      color: $black-color;
      text-transform: capitalize;
      font-weight: 500;
    }
  }

  &__arrow {
    position: absolute;
    right: 1rem;
    top: 1.8rem;

    &::before {
      content: '';
      display: block;
      width: 0px;
      height: 0px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 16px solid $gray50-color;
    }
  }
}

.select2-dropdown {
  display: block;
  background-color: $white-color;
  border: 1px solid $gray50-color;
  margin: 8px 0;
}

.select2-search {
  display: block;
  padding: 1rem;

  &--hide {
    display: none;
  }

  &__field {
    width: 100%;
    outline: 0;
    height: 3.5rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 3.5rem;
    border: 1px solid #C8C8C8;
    padding: 1.2rem;
    box-shadow: none;

    &:focus {
      outline: 0;
    }
  }
}

.select2-results {
  position: relative;
  display: block;

  &__options {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 21rem;
    overflow-y: auto;
  }

  &__option {
    display: block;
    height: 4.8rem;
    line-height: 4.8rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.6rem;
    color: $gray25-color;
    padding: 0 1rem;
    cursor: pointer;
    border-bottom: 1px solid #E6E6E6;

    &:last-child {
      border-bottom: 0;
    }

    &--group {
      text-transform: uppercase;
    }

    &--selected {
      background-color: $white-color;
    }

    &--highlighted {
      background-color: $white-color;
    }

    &--selectable {
      padding: 0 2rem;
    }

    &--disabled {
      display: none;
    }
  }
}
