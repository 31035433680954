.icon {
  font-family: $icomoon-font;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &--quote-left:before {
    content: "\e909";
  }

  &--quote-right:before {
    content: "\e90a";
  }

  &--youtube:before {
    content: "\e905";
  }

  &--twitter:before {
    content: "\e906";
  }

  &--instagram:before {
    content: "\e907";
  }

  &--facebook:before {
    content: "\e908";
  }

  &--arrow-right:before {
    content: "\e900";
  }

  &--arrow-left:before {
    content: "\e904";
  }

  &--burger:before {
    content: "\e901";
  }

  &--location:before {
    content: "\e902";
  }

  &--user:before {
    content: "\e903";
  }
}
