.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 1px solid $red-color;
  border-radius: 4px;
  padding: 1.5rem;
  font-family: $second-font;
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 700;

  @include minW(992) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  @include minW(1600) {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  &--block {
    display: flex;
    width: 100%;
  }

  &--red {
    background-color: $red-color;
    color: $white-color;
  }

  &--white {
    border-color: $white-color;
    color: $white-color;
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 1.2rem 3.2rem;

    @include minW(992) {
      font-size: 2.1rem;
      line-height: 2.4rem;
    }

    @include minW(1600) {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  }

  &--white-border {
    border-color: $white-color;
    color: $white-color;
    font-size: 1.6rem;
    line-height: 2.2rem;
    padding: 8px 2.4rem;
    border-radius: 3rem;
  }

  &--black {
    border-color: $black-color;
    background-color: $black-color;
    color: $white-color;
  }

  &--link {
    padding: 0;
    border: 0;
  }
}
