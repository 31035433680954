.why-vidotour {
  position: relative;
  padding: 4rem 0;
  overflow: hidden;

  @include minW(992) {
    padding: 4.8rem 0;
  }

  @include minW(1600) {
    padding: 6.5rem 0;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/why-vidotour/line-mb.png');
    background-size: 100% 100%;
    z-index: 1;

    @include minW(992) {
      background-image: url('../img/why-vidotour/line-dt.png');
    }
  }

  &__main {
    position: relative;
    z-index: 2;
  }

  &__head {
    text-align: center;
    margin-bottom: 5.6rem;

    @include minW(992) {
      margin-bottom: 6.5rem;
    }

    @include minW(1600) {
      margin-bottom: 8.5rem;
    }
  }

  &__title {
    font-family: $second-font;
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 0;

    @include minW(992) {
      font-size: 3rem;
      line-height: 3.6rem;
    }

    @include minW(1600) {
      font-size: 4rem;
      line-height: 4.8rem;
      margin-bottom: 1.6rem;
    }
  }

  &__subtitle {
    font-family: $second-font;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    color: $gray50-color;

    @include minW(992) {
      font-size: 2.1rem;
      line-height: 2.4rem;
    }

    @include minW(1600) {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  }

  &__body {
    margin: 0 -1.5rem;

    @include minW(992) {
      margin: 0;
    }

    .slick {
      &-list {
        margin: 0 -1rem;
        padding: 0 4rem 0 1.5rem;

        @include minW(992) {
          padding: 0;
        }
      }

      &-track {
        display: flex !important;
      }

      &-slide {
        padding: 2rem 1rem;
        height: inherit !important;
      }
    }
  }
}

.card-why-vidotour {
  position: relative;
  overflow: hidden;
  text-align: center;
  background-color: $white-color;
  box-shadow: 0 0 1rem rgba($black-color, 0.1);
  padding: 3.4rem 6rem;
  height: 100%;

  @include minW(992) {
    box-shadow: none;
  }

  @include minW(1600) {
    padding: 3.4rem 6rem;
  }

  &__thumb {
    margin-bottom: 3rem;

    @include minW(1600) {
      max-width: 26.7rem;
      margin-bottom: 4rem;
    }

    .img {
      max-width: 15.2rem;
      margin: 0 auto;

      @include minW(1600) {
        max-width: 26.7rem;
      }
    }
  }

  &__title {
    font-family: $second-font;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
