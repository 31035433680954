.taber {
  position: relative;

  &--vertical {
    display: flex;
    flex-wrap: nowrap;

    >.taber {
      &__menu {
        display: block;
        max-width: 26%;
        flex-basis: 26%;
        margin-bottom: 4rem;

        .taber {
          &__link {
            &::after {
              display: none;
            }
          }
        }
      }

      &__body {
        max-width: 74%;
        flex-basis: 74%;
      }
    }
  }

  &--people {
    .taber {
      &__menu {
        margin: 0 -1.2rem;
        overflow-x: auto;
        white-space: nowrap;
      }

      &__link {
        padding: 0 1.2rem;
        min-width: 8.8rem;
      }
    }
  }

  &--year {
    .taber {
      &__menu {
        justify-content: flex-start;
        overflow-x: auto;
        white-space: nowrap;
      }

      &__link {
        margin-left: 4rem;
        margin-bottom: 2.5rem;
        color: $gray80-color;

        &:first-child {
          margin-left: 0;
        }

        &::after {
          display: none;
        }

        @include hover {
          strong {
            span {
              &::before {
                background-color: $red-color;
              }
            }
          }
        }

        &.is-active {
          strong {
            span {
              &::before {
                background-color: $red-color;
              }
            }
          }
        }

        strong {
          display: block;
          font-size: 2rem;
          line-height: 3.2rem;
          font-weight: 700;

          span {
            position: relative;

            &::before {
              content: '';
              display: block;
              width: 2px;
              height: 1.6rem;
              background-color: $gray80-color;
              margin: 0 auto;
              transition: background-color 0.3s ease-out;
            }
          }
        }
      }
    }
  }

  &__menu {
    display: flex;
  }

  &__link {
    display: block;
    width: auto;
    font-size: 1.8rem;
    line-height: 2.1rem;
    font-weight: 400;
    text-decoration: none;
    color: $gray25-color;
    position: relative;
    transition: all 0.3s ease-out;
    white-space: nowrap;
    margin-bottom: 3rem;
    padding-bottom: 1.2rem;

    @include minW(1600) {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 4rem;
    }

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 4px;
      background-color: $red-color;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      transition: width 0.3s ease-out;
    }

    @include hover {
      color: $red-color;

      &::after {
        width: 8rem;
      }
    }

    &.is-active {
      color: $red-color;

      &::after {
        width: 8rem;
      }
    }
  }

  &__content {
    position: relative;
    height: 0;
    opacity: 0;
    overflow: hidden;
    color: $black-color;

    &.is-active {
      opacity: 1;
      height: auto;
      overflow: unset;
    }

    .img {
      margin-bottom: 1.5rem;
    }

    ul {
      list-style: none;

      li {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 1.2rem;

        strong {
          min-width: 16rem;
        }
      }
    }
  }

  &__thumb {
    font-size: 1rem;

    p {
      margin-top: -10%;
    }
  }
}
