.welcome {
  position: relative;
  width: 100%;
  background-color: $red-color;
  overflow: hidden;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/welcome/line.png');
    background-size: 100% 100%;
    display: none;

    @include minW(992) {
      display: block;
    }
  }

  &__cloud {
    position: absolute;
    left: 0;
    top: 7rem;
    display: none;

    @include minW(992) {
      top: 5.3rem;
      max-width: 74rem;
      display: block;
    }

    @include minW(1600) {
      top: 7rem;
      max-width: 98.6rem;
    }
  }

  &__line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
    display: none;

    @include minW(992) {
      display: block;
    }
  }

  &__main {
    position: relative;
    padding: 6.5rem 0 3.2rem;
    z-index: 2;

    @include minW(992) {
      padding: 10.5rem 0 6.75rem;
    }

    @include minW(1600) {
      padding: 14rem 0 9rem;
    }
  }

  .slick {
    &-dots {
      margin: 0 auto;
      width: 100%;
      padding: 0 1.5rem;
      max-width: 127.8rem;

      @include minW(1200) {
        margin: -6rem auto 0;
      }

      @include minW(1600) {
        margin: -8rem auto 0;
        max-width: 169.4rem;
      }
    }
  }
}

.card-welcome {
  position: relative;
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 400;
  color: $white-color;
  text-align: center;

  @include minW(992) {
    text-align: left;
  }

  @include minW(1600) {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  &__title {
    font-family: $second-font;
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 700;
    margin-bottom: 0;

    @include minW(992) {
      font-size: 4.8rem;
      line-height: 5.4rem;
    }

    @include minW(1600) {
      font-size: 6.4rem;
      line-height: 7.2rem;
    }
  }

  &__subtitle {
    font-family: $second-font;
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 400;
    margin-bottom: 2.4rem;

    @include minW(992) {
      font-size: 2.4rem;
      line-height: 2.7rem;
    }

    @include minW(1600) {
      font-size: 3.2rem;
      line-height: 3.6rem;
    }
  }

  &__thumb {
    margin-bottom: 1.5rem;
    margin: 0 -1.2rem;

    @include minW(992) {
      margin-bottom: 0;
      margin: 0 -2rem;
    }
  }

  &__desc {
    margin-bottom: 2.4rem;

    @include minW(992) {
      margin-bottom: 4.6rem;
    }
  }
}
