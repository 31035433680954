.footer {
  background-color: $red-color;
  padding: 3.6rem 0;
  color: $white-color;
  overflow: hidden;

  @include minW(1600) {
    padding: 4.8rem 0;
  }

  &__head {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;

    @include minW(992) {
      margin-bottom: 7.2rem;
      justify-content: flex-start;
    }
  }

  &__logo {
    width: 13.2rem;

    @include minW(992) {
      width: 16.5rem;
    }

    @include minW(1600) {
      width: 22rem;
    }
  }
}

.menu {
  list-style: none;
  padding: 0;
  margin-bottom: 3.8rem;
  text-align: center;

  @include minW(992) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    text-align: left;
  }

  &__item {
    border-bottom: 1px solid rgba($white-color, 0.25);
    padding: 1.6rem 0;

    @include minW(992) {
      border-bottom: 0;
      padding: 0;
    }
  }

  &__link {
    font-size: 1.2rem;
    line-height: 1.65rem;
    font-weight: 700;
    color: $white-color;
    margin: 0 2.8rem;
    transition: color 0.3s ease-out;

    @include hover {
      color: $gray80-color;
    }

    @include minW(1600) {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }
}

.info {
  text-align: center;
  margin-bottom: 3.8rem;

  @include minW(992) {
    margin-bottom: 0;
    text-align: left;
  }

  &__title {
    text-transform: uppercase;
  }

  &__email {
    a {
      text-decoration: underline;
    }
  }

  &__link {
    color: $white-color;
    transition: color 0.3s ease-out;

    @include hover {
      color: $gray80-color;
    }
  }

  p {
    margin-bottom: 8px;

    span {
      margin-top: 6px;
      display: block;
    }
  }
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;

  @include minW(992) {
    justify-content: flex-end;
  }

  &__link {
    margin: 0 2rem;
    font-size: 1.8rem;
    color: $white-color;
    opacity: 1;
    transition: opacity 0.3s ease-out;

    @include hover {
      opacity: 0.75;
    }

    @include minW(992) {
      margin: 0 0 0 3rem;
    }

    @include minW(1600) {
      font-size: 2.4rem;
      margin: 0 0 0 4rem;
    }
  }
}

.copyright {
  margin-bottom: 0;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;

  @include minW(992) {
    text-align: right;
  }

  @include minW(1600) {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}
