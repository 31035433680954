.people {
  position: relative;

  &__name {
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__position {
    color: $red-color;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  .row {
    margin: 0 -1.2rem;

    &__col {
      padding: 0 1.2rem;
    }
  }
}
