.collapse {
  position: relative;
  margin-bottom: 1.5rem;

  &--travel-tips {
    .collapse {
      &__item {
        @include minW(992) {
          display: flex;
          flex-wrap: nowrap;
        }

        &.is-show {
          .collapse {
            &__arrow {
              transform: rotate(90deg);
              margin-top: -8px;
              border-left: 1.2rem solid $red-color;
            }
          }
        }
      }

      &__head,
      &__body {
        @include minW(992) {
          max-width: 50%;
          flex-basis: 50%;
        }
      }

      &__content {
        @include minW(992) {
          padding: 0 8.2rem;
        }
      }

      &__head {
        padding: 1.4rem 1.8rem;
      }

      &__title {
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 400;
      }

      &__arrow {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 1.6rem solid $gray50-color;
        right: 1.8rem;

        &::before,
        &::after {
          display: none;
        }
      }

      &__body {

        @include minW(992) {
          position: absolute;
          top: 0;
          left: 50%;
          opacity: 0;
        }

        &.is-show {
          @include minW(992) {
            opacity: 1;
          }
        }
      }
    }
  }

  &__item {
    margin-bottom: 1.6rem;

    @include minW(992) {
      margin-bottom: 2rem;
    }

    &.is-show {
      .collapse {
        &__arrow {
          &::after {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &__head {
    position: relative;
    padding-right: 3rem;
    padding: 1.6rem 1.2rem;
    border: 1px solid $gray80-color;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;

    @include minW(992) {
      padding: 1.6rem 3rem;
    }

    @include minW(1600) {
      padding: 2.2rem 4rem;
    }
  }

  &__body {
    position: relative;
    overflow: hidden;
    height: 0;
  }

  &__content {
    padding: 1.6rem 8px 0;

    @include minW(992) {
      padding: 3rem;
    }

    @include minW(1600) {
      padding: 4rem;
    }
  }

  &__title {
    font-family: $second-font;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 700;
    margin-bottom: 0;

    @include minW(992) {
      font-size: 2.1rem;
      line-height: 2.4rem;
    }

    @include minW(1600) {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  }

  &__arrow {
    position: absolute;
    right: 1.4rem;
    top: 50%;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    border: 0;
    outline: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    @include minW(992) {
      right: 2.8rem;
      width: 3rem;
      height: 3px;
    }

    @include minW(1600) {
      right: 3.8rem;
      width: 4rem;
      height: 4px;
    }

    &:hover,
    &:active {
      outline: 0;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      width: 2rem;
      height: 2px;
      position: absolute;
      background-color: $gray50-color;
      transition: transform 0.3s ease-out;

      @include minW(992) {
        width: 3rem;
        height: 3px;
      }

      @include minW(1600) {
        width: 4rem;
        height: 4px;
      }
    }
  }
}
