.banner {
  position: relative;
  margin-bottom: 3rem;
  height: 32rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @include minW(992) {
    height: 48rem;
    margin-bottom: 9rem;
  }

  @include minW(1600) {
    margin-bottom: 12rem;
  }

  &__body {
    position: absolute;
    left: 0;
    bottom: 3.2rem;
    width: 100%;
    z-index: 2;

    @include minW(992) {
      bottom: 6.5rem;
    }
  }

  &__title {
    font-size: 3.2rem;
    line-height: 3.2rem;
    font-weight: 700;
    color: $white-color;

    @include minW(992) {
      font-size: 4.8rem;
      line-height: 5.4rem;
      padding-top: 9.9rem;
      margin-bottom: 0;
    }

    @include minW(1600) {
      font-size: 6.4rem;
      line-height: 7.2rem;
      padding-top: 13.2rem;
      margin-bottom: 0;
    }
  }
}
