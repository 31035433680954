@include fontFace('icomoon', '../fonts/', 'icomoon', 400, normal);
@include fontFace('Helvetica', '../fonts/', 'Helvetica', 400, normal);
@include fontFace('Helvetica', '../fonts/', 'Helvetica-Bold', 700, normal);
@include fontFace('Rubik', '../fonts/', 'Rubik-Light', 300, normal);
@include fontFace('Rubik', '../fonts/', 'Rubik-LightItalic', 300, italic);
@include fontFace('Rubik', '../fonts/', 'Rubik-Regular', 400, normal);
@include fontFace('Rubik', '../fonts/', 'Rubik-Italic', 400, italic);
@include fontFace('Rubik', '../fonts/', 'Rubik-Medium', 500, normal);
@include fontFace('Rubik', '../fonts/', 'Rubik-MediumItalic', 500, italic);
@include fontFace('Rubik', '../fonts/', 'Rubik-SemiBold', 600, normal);
@include fontFace('Rubik', '../fonts/', 'Rubik-SemiBoldItalic', 600, italic);
@include fontFace('Rubik', '../fonts/', 'Rubik-Bold', 700, normal);
@include fontFace('Rubik', '../fonts/', 'Rubik-BoldItalic', 700, italic);
@include fontFace('Rubik', '../fonts/', 'Rubik-ExtraBold', 800, normal);
@include fontFace('Rubik', '../fonts/', 'Rubik-ExtraBoldItalic', 800, italic);
@include fontFace('Rubik', '../fonts/', 'Rubik-Black', 900, normal);
@include fontFace('Rubik', '../fonts/', 'Rubik-BlackItalic', 900, italic);
