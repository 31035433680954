* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  font-size: 10px;
  min-width: 300px;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

body {
  font-family: $primary-font;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: $black-color;
  background-color: $white-color;

  @include minW(1600) {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  &.is-noscroll {
    overflow: hidden;
  }
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 1.6rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1.6rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1.6rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 1rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1.6rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.5rem;
}

sup {
  top: -1rem;
}

a {
  color: $red-color;
  text-decoration: none;

  &:not([href]) {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1.5rem;
}

pre {
  margin-top: 0;
  margin-bottom: 1.6rem;
  overflow: auto;
}

figure {
  margin: 0 0 1.6rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;

  &:focus {
    outline: 0.1rem dotted;
    outline: 0.5rem auto -webkit-focus-ring-color;
  }
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;

  &::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

input {

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 6000000s 0s, color 6000000s 0s;
  }
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 1rem;
  font-size: 2.4rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"] {

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: auto;
  }
}

[type="search"] {
  outline-offset: -0.2rem;
  -webkit-appearance: none;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

[type="file"] {
  &::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.main {
  min-height: calc(100% - 41rem);
}

.line-left {
  border-left: 4px solid #A4C3C5;
  padding-left: 1.2rem;
}

.display-dt {
  display: none;

  @include minW(992) {
    display: block;
  }
}
