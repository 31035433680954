$icomoon-font: 'icomoon',
sans-serif !default;
$primary-font: 'Helvetica',
sans-serif !default;
$second-font: 'Rubik',
sans-serif !default;

$white-color: #fff !default;
$black-color: #000 !default;
$red-color: #ff681a !default;
$gray25-color: #404040 !default;
$gray50-color: #808080 !default;
$gray80-color: #ccc !default;
$gray90-color: #E6E6E6 !default;
