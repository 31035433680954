.inquire {
  position: relative;
  text-align: center;
  padding: 6.5rem 0 13rem;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/inquire/line-mb.png');
    background-size: 100% 100%;
    z-index: 1;

    @include minW(992) {
      background-image: url('../img/inquire/line-dt.png');
    }
  }

  &__main {
    position: relative;
    z-index: 2;
  }

  &__title {
    font-family: $second-font;
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 2.4rem;

    @include minW(1600) {
      font-size: 3.2rem;
      line-height: 3.6rem;
      margin-bottom: 3rem;
    }
  }
}
