.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: background-color 0.3s ease-out;
  background-color: $red-color;
  z-index: 99;

  @include minW(992) {
    background-color: transparent;
  }

  &.is-move {
    @include minW(992) {
      background-color: $red-color;
    }

    .header {
      &__body {

        @include minW(992) {
          border-bottom-color: $red-color;
          padding: 6px;
        }

        .logo {
          @include minW(992) {
            width: 13.5rem;
          }

          @include minW(1600) {
            width: 18rem;
          }
        }
      }
    }

    .navbar {
      &__item {
        &:last-child {
          .navbar {
            &__link {
              @include minW(992) {
                padding: 7px 2.4rem;
              }
            }
          }
        }
      }
    }

    .popup {
      @include minW(992) {
        top: 5.9rem;
      }
    }
  }

  &.is-show {
    .navbar {
      transform: translateX(0);
    }

    .toggle {
      &__icon {
        background-color: transparent;

        &::before {
          transform: rotate(45deg);
          top: 0;
        }

        &::after {
          transform: rotate(-45deg);
          top: 0;
          width: 2.4rem;
        }
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 0;
    transition: all 0.3s ease-out;

    @include minW(992) {
      padding: 1.2rem 0;
      border-bottom: 1px solid $white-color;
    }

    @include minW(1600) {
      padding: 1.6rem 0;
    }
  }
}

.logo {
  position: relative;
  width: 13rem;
  transition: all 0.3s ease-out;
  z-index: 2;

  @include minW(992) {
    width: 16.5rem;
  }

  @include minW(1600) {
    width: 22rem;
  }
}

.navbar {
  position: fixed;
  top: 4.9rem;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 4.9rem);
  transform: translateX(100%);
  list-style: none;
  background-color: $red-color;
  overflow: hidden;
  overflow-y: auto;
  transition: transform 0.3s ease-out;

  @include minW(992) {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    bottom: auto;
    display: flex;
    align-items: center;
    //justify-content: flex-end;
    padding: 0;
    margin: 0;
    background-color: transparent;
    transform: none;
    width: auto;
    height: 100%;
    overflow: unset;
  }

  &__item {
    display: flex;
    justify-content: center;
    padding: 2.4rem 1.5rem;
    border-bottom: 1px solid $gray80-color;

    @include minW(992) {
      padding: 0;
      border-bottom: 0;
      margin-left: 8rem;
    }

    &:last-child {
      border-bottom: 0;

      .navbar {
        &__link {
          display: flex;
          align-items: center;
          justify-content: center;

          @include minW(992) {
            padding: 1.2rem 2.4rem;
            border: 1px solid $white-color;
            border-radius: 4px;
            transition: all 0.3s ease-out;
          }

          @include hover {
            @include minW(992) {
              border: 1px solid $gray80-color;
            }
          }

          .icon {
            font-size: 2.4rem;
            margin-right: 1rem;

            @include minW(992) {
              font-size: 1.8rem;
            }

            @include minW(1600) {
              font-size: 2.4rem;
            }
          }
        }
      }
    }
  }

  &__link {
    font-family: $second-font;
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 700;
    color: $white-color;
    transition: color 0.3s ease-out;

    @include minW(992) {
      font-size: 1.35rem;
      line-height: 1.8rem;
      font-weight: 500;
    }

    @include hover {
      color: $gray80-color;
    }

    @include minW(1600) {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }
}

.toggle {
  position: absolute;
  top: 50%;
  right: 0;
  width: 2.4rem;
  height: 2rem;
  border: 0;
  outline: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  transform: translateY(-50%);

  @include minW(992) {
    display: none;
  }

  &:hover,
  &:active {
    outline: 0;
  }

  &__icon {
    position: absolute;
    width: 2.4rem;
    height: 2px;
    background-color: $white-color;
    left: 0;
    transition: background-color 0.15s ease-out;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 2.4rem;
      height: 2px;
      background-color: $white-color;
      position: absolute;
      right: 0;
      transition: all 0.3s ease-out;
    }

    &::before {
      top: -8px;
    }

    &::after {
      top: 8px;
      width: 2rem;
    }
  }
}
