.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick {
  &-slide {
    opacity: 0.9;
  }

  &-current {
    opacity: 1;
  }

  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8rem;
    height: 100%;
    border: 0;
    outline: 0;
    background-color: $red-color;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    &::before {
      content: '';
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      border-left: 4px solid $white-color;
      border-bottom: 4px solid $white-color;
    }

    @include hover {
      outline: 0;
    }
  }

  &-prev {
    left: -8.6rem;
    padding-left: 1rem;

    &::before {
      transform: rotate(45deg);
    }
  }

  &-next {
    right: -8.9rem;
    padding-right: 1rem;

    &::before {
      transform: rotate(-135deg);
    }
  }

  &-disabled {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &-dots {
    position: relative;
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    z-index: 2;

    li {
      &.slick-active {
        button {
          opacity: 1;
        }
      }

      button {
        background-color: $white-color;
        border: 0;
        outline: 0;
        width: 4.8rem;
        height: 3px;
        margin: 6px;
        font-size: 0;
        display: block;
        opacity: 0.25;
        transition: opacity 0.3s ease-out;

        @include minW(1600) {
          width: 6.4rem;
          height: 4px;
          margin: 8px;
        }
      }
    }
  }
}
