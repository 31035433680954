.contact {
  position: relative;
  margin-bottom: 3rem;
  overflow: hidden;

  @include minW(992) {
    margin-bottom: 9rem;
  }

  @include minW(1600) {
    margin-bottom: 12rem;
  }

  &__body {
    position: relative;
  }

  &__or {
    position: absolute;
    left: 50%;
    top: 0;
    width: 0;
    height: 100%;
    border-left: 1px solid $gray50-color;

    span {
      position: absolute;
      top: 50%;
      left: -8px;
      font-weight: 700;
      color: $red-color;
      background-color: $white-color;
      padding: 5px 0;
    }

  }

  &__title {
    font-family: $second-font;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;

    @include minW(992) {
      font-size: 1.8rem;
      line-height: 2.1rem;
    }

    @include minW(1600) {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

  }

  &__desc {
    margin-bottom: 4rem;
  }

  &__note {
    margin-bottom: 1.2rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $gray50-color;
  }

  &__mb {
    position: relative;
    text-align: center;
    padding-top: 2.4rem;
    margin-top: 3.4rem;

    &::before {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -4rem;
      content: '';
      display: block;
      width: 8rem;
      height: 1px;
      background-color: $red-color;
    }

    a {
      border: 1px solid $gray50-color;
      padding: 1.6rem 1.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: $second-font;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 700;
      border-radius: 8px;

      .icon {
        font-size: 3.2rem;
      }
    }
  }

  .row {
    margin: 0 -13.2rem;

    &__col {
      padding: 0 13.2rem;
    }
  }

  .form {
    &__label {
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-weight: 700;
      margin-bottom: 1rem;

      sup {
        top: -0.3rem;
        color: $red-color;
        padding-left: 3px;
      }
    }

    .textarea {
      position: relative;
      padding: 1rem 0 2.4rem;
      border-bottom: 1px solid $gray80-color;
    }

    .counter {
      position: absolute;
      right: 0;
      bottom: 5px;

      &__slash {
        padding: 0 3px;
      }
    }

    textarea {
      resize: none;
      height: 8rem;
      line-height: 2rem;
      border-bottom: 0;
    }
  }
}
