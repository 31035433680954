.banner-destination {
  position: relative;
  width: 100%;
  height: 32rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  margin-bottom: 4rem;

  @include minW(992) {
    height: 36rem;
    margin-bottom: 9rem;
  }

  @include minW(1600) {
    height: 48rem;
    margin-bottom: 12rem;
  }

  &__title {
    font-size: 3.2rem;
    line-height: 3.2rem;
    font-weight: 700;
    color: $white-color;
    margin-bottom: 3.2rem;

    @include minW(992) {
      font-size: 4.8rem;
      line-height: 5.4rem;
      margin-bottom: 4.8rem;
    }

    @include minW(1600) {
      font-size: 6.4rem;
      line-height: 7.2rem;
      margin-bottom: 6.5rem;
    }
  }
}

.country {
  position: relative;
  margin-bottom: 3rem;

  @include minW(992) {
    margin-bottom: 9rem;
  }

  @include minW(1600) {
    margin-bottom: 12rem;
  }

  &__list {

    @include minW(992) {
      display: flex;
      flex-wrap: nowrap;
      margin: 0 -1.1rem;
    }
  }

  &__item {
    @include minW(992) {
      max-width: 20%;
      flex-basis: 20%;
      padding: 0 1.1rem;
    }
  }
}

.card-country {
  position: relative;

  &__link {
    position: relative;
    display: block;
    padding: 1.6rem 1.2rem;
    border: 1px solid $gray80-color;
    margin-bottom: 1.6rem;
    overflow: hidden;

    @include minW(992) {
      padding: 0;
      border: 0;
      margin-bottom: 0;
      padding-top: 120%;
    }

    @include hover {
      .img {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }

    .img {
      display: none;

      @include minW(992) {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);
        transition: transform 0.3s ease-out;
      }
    }
  }

  &__title {
    width: 100%;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 700;
    letter-spacing: 1px;
    color: $gray25-color;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;

    @include minW(992) {
      font-size: 1.8rem;
      line-height: 2.1rem;
      position: absolute;
      justify-content: center;
      left: 0;
      bottom: 0;
      padding: 3.2rem 1.6rem;
      text-align: center;
      color: $white-color;
      text-transform: uppercase;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
    }

    @include minW(1600) {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    .icon {
      @include minW(992) {
        display: none;
      }
    }
  }
}

.banner-simple {
  position: relative;
  margin-bottom: 9.6rem;

  &__body {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-bottom: 6.8rem;
  }

  &__title {
    font-size: 6.4rem;
    line-height: 7.2rem;
    color: $white-color;
    margin-bottom: 0;
  }
}

.intro {
  position: relative;
  margin-bottom: 3rem;
  overflow: hidden;

  @include minW(992) {
    margin-bottom: 9rem;
  }

  @include minW(1600) {
    margin-bottom: 12rem;
  }

  &__body {
    position: relative;

    .img {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      padding: 0 8.7rem 0 14.5rem;
    }
  }

  &__title {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    margin-bottom: 1.6rem;

    @include minW(992) {
      font-size: 3rem;
      line-height: 3.6rem;
      margin-bottom: 2.4rem;
    }

    @include minW(1600) {
      font-size: 4rem;
      line-height: 4.8rem;
      margin-bottom: 3.2rem;
    }
  }
}

.intro-1 {
  position: relative;
  margin-bottom: 3rem;
  overflow: hidden;

  @include minW(992) {
    margin-bottom: 9rem;
    min-height: 28rem;
  }

  @include minW(1600) {
    margin-bottom: 12rem;
    min-height: 37.5rem;
  }

  &__body {
    position: relative;

    .img {
      display: none;

      @include minW(992) {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        padding: 0 8.7rem 0 14.5rem;
        display: block;
      }
    }
  }

  &__content {
    max-width: 56rem;
  }
}

.tourist {
  position: relative;
  margin-bottom: 4rem;
  overflow: hidden;

  @include minW(992) {
    margin-bottom: 6rem;
  }

  @include minW(1600) {
    margin-bottom: 8rem;
  }

  &__slider {
    margin: 0 -1.5rem;

    @include minW(992) {
      margin: 0;
    }
  }

  &__thumb {
    margin: -1.5rem -1.5rem 0;

    @include minW(992) {
      margin: -3rem 0 0;
    }

    .slick {
      &-list {
        padding: 0 1.6rem;
        margin: 0 -3px;

        @include minW(992) {
          padding: 0;
        }
      }

      &-slide {
        padding: 0 3px;
      }
    }
  }
}

.card-tourist {
  position: relative;
  color: $white-color;

  &--thumb {
    width: 100%;
    height: 6rem;

    @include minW(992) {
      height: 7.2rem;
    }

    @include minW(992) {
      height: 9.5rem;
    }

    .card-tourist {
      &__title {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        font-size: 1.2rem;
        line-height: 1.4rem;
        padding: 1rem;

        @include minW(992) {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }

        @include minW(1600) {
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }
    }

    .img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__thumb {
    width: 100%;
    height: 44rem;

    @include minW(992) {
      height: 48rem;
    }

    @include minW(1600) {
      height: 64rem;
    }

    .img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;

    @include minW(992) {
      bottom: 6rem;
    }

    @include minW(1600) {
      bottom: 8rem;
    }
  }

  &__title {
    font-family: $second-font;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    margin-bottom: 4px;

    @include minW(992) {
      font-size: 1.8rem;
      line-height: 2.1rem;
      margin-bottom: 1.2rem;
    }

    @include minW(1600) {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 1.6rem;
    }
  }

  &__content {
    max-width: 43.5rem;
    padding-right: 13rem;
    position: relative;

    .btn {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.travel-tips {
  position: relative;
  margin-bottom: 6rem;

  @include minW(992) {
    margin-bottom: 9rem;
  }

  @include minW(1600) {
    margin-bottom: 12rem;
  }
}

.tourist-detail {
  position: relative;
  margin-bottom: 6rem;

  @include minW(992) {
    margin-bottom: 9rem;
  }

  @include minW(1600) {
    margin-bottom: 12rem;
  }

  &__slider {
    margin: 0 -1.5rem;
  }

  &__thumb {
    margin-top: -3rem;
    margin-bottom: 3rem;
    position: relative;
    overflow: hidden;

    @include minW(992) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .slick {
      &-list {
        margin: 0 -6px;
        padding: 0 1.5em;

        @include minW(992) {
          padding: 0 3rem 0 0;
        }
      }

      &-slide {
        padding: 0 6px;
        opacity: 0.8;
      }

      &-current {
        opacity: 1;
      }
    }
  }

  &__body {
    position: relative;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__title {
    font-family: $second-font;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    color: $white-color;
    position: absolute;
    top: 30%;
    left: 1.5rem;
    display: none;

    @include minW(992) {
      display: block;
      position: relative;
      color: $black-color;
      font-size: 3rem;
      line-height: 3.6rem;
      top: auto;
      left: auto;
    }

    @include minW(1600) {
      font-size: 4rem;
      line-height: 4.8rem;
    }
  }

  &__note {
    border-left: 4px solid $red-color;
    padding-left: 1.2rem;
    color: $gray50-color;
  }

  &__subtitle {
    font-family: $second-font;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    color: $black-color;
    margin-bottom: 1.2rem;

    @include minW(992) {
      font-size: 1.8rem;
      line-height: 2.1rem;
      margin-bottom: 1.6rem;
    }

    @include minW(1600) {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }

  &__desc {
    margin-bottom: 0;
  }

  &__show {
    margin-bottom: 1.2rem;
  }
}

.card-slide {
  position: relative;
  width: 100%;
  height: 44rem;

  @include minW(992) {
    height: 40.5rem;
  }

  @include minW(1600) {
    height: 54rem;
  }

  &--thumb {
    height: 6rem;

    @include minW(992) {
      height: 7.2rem;
    }

    @include minW(1600) {
      height: 9.5rem;
    }
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
