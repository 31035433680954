.reviews {
  position: relative;
  padding: 4rem 0;
  overflow: hidden;

  @include minW(992) {
    padding: 4.9rem 0;
  }

  @include minW(1600) {
    padding: 6.5rem 0;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #E0C3B3;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/reviews/line-mb.png');
    background-size: 100% 100%;
    z-index: 1;

    @include minW(992) {
      background-image: url('../img/reviews/line-dt.png');
    }
  }

  &__main {
    position: relative;
    z-index: 2;
  }

  &__head {
    text-align: center;
    margin-bottom: 2.8rem;

    @include minW(992) {
      margin-bottom: 6.4rem;
    }

    @include minW(1600) {
      margin-bottom: 8.5rem;
    }
  }

  &__title {
    font-family: $second-font;
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 0;
    color: #78423F;

    @include minW(992) {
      font-size: 3rem;
      line-height: 3.6rem;
    }

    @include minW(1600) {
      font-size: 4rem;
      line-height: 4.8rem;
      margin-bottom: 1.6rem;
    }
  }

  &__subtitle {
    font-family: $second-font;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    color: $gray50-color;
    color: #BD6762;

    @include minW(992) {
      font-size: 2.1rem;
      line-height: 2.4rem;
    }

    @include minW(1600) {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  }

  &__body {
    position: relative;
    text-align: center;

    @include minW(992) {
      text-align: left;
    }

    .slick {
      &-arrow {
        width: 9.6rem;
        height: 4.8rem;
        top: auto;
        transform: none;
        bottom: 6rem;
        background-color: transparent;
        border: 1px solid $red-color;
        transition: background-color 0.3s ease-out;

        &::before {
          width: 1.2rem;
          height: 1.2rem;
          border-left: 2px solid $red-color;
          border-bottom: 2px solid $red-color;
          transition: border 0.3s ease-out;
        }

        @include hover {
          background-color: $red-color;

          &::before {
            border-left: 2px solid $white-color;
            border-bottom: 2px solid $white-color;
          }
        }
      }

      &-next {
        right: 0;
      }

      &-prev {
        left: auto;
        right: 10.4rem;
      }

      &-dots {
        justify-content: center;
        flex-wrap: wrap;

        li {
          button {
            background-color: $red-color;
          }
        }
      }
    }
  }
}

.card-reviews {
  position: relative;

  &__thumb {
    margin-bottom: 2rem;

    @include minW(992) {
      margin-bottom: 0;
    }
  }

  &__body {
    @include minW(992) {
      padding-left: 3.2rem;
      padding-bottom: 10rem;
    }

    @include minW(1600) {
      padding-left: 3.2rem;
      padding-bottom: 0rem;
    }
  }

  &__content {
    position: relative;
    color: #78423F;
    margin-bottom: 3.2rem;

    &::before,
    &::after {
      position: absolute;
      font-family: $icomoon-font;
      display: none;
      color: $red-color;

      @include minW(992) {
        display: inline-block;
      }
    }

    &::before {
      content: '\e909';
      left: -3.2rem;
    }

    &::after {
      content: '\e90a';
      margin-left: 1.5rem;
      margin-top: 0.5rem;
    }
  }

  &__name {
    font-weight: 700;
    color: $red-color;
  }

  &__country {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #BD6762;
  }
}
